import ModalTemplate from "./ModalTemplate.js";
import styled from "styled-components";


const AttributeInfo = ({ showModal, setShowModal }) => {

    return (
        <ModalTemplate showModal={showModal} setShowModal={setShowModal}>
            
                <h1>The Attributes</h1>
                <p>In the digital space, certain attributes are widely recognised as essential for success. These attributes contribute to a well-rounded skill set that goes beyond technical prowess, fostering an environment conducive to innovation, growth, and adaptability.</p>

                <ul>
                    <li><b>Communication and Empathy:</b> Effective communication is pivotal in the digital domain, where collaborative projects and diverse teams are the norms. Empathy enhances communication by fostering an understanding of various perspectives, leading to more effective and meaningful connections.</li>
                    <li><b>Creative and Flexible:</b> Creativity and flexibility are invaluable in navigating the ever-evolving landscape of digital technology. They enable professionals to adapt to changes and devise innovative solutions to complex challenges, thereby driving business growth and success.</li>
                    <li><b>Curious and Inquisitive:</b> Curiosity is a critical trait for digital professionals. It leads to better problem-solving, more innovative solutions, and a greater capacity to adapt to new challenges. A culture of curiosity in a company has been linked to business growth, as it encourages employees to seek better solutions and approaches.</li>
                    <li><b>Detailed and Technically Minded:</b> Attention to detail and technical acumen are fundamental in the digital space, where precision and expertise play crucial roles in project success and innovation.</li>
                    <li><b>Efficiency and Improvement:</b> The ability to work efficiently and continually seek improvement is vital in a fast-paced digital environment. This approach ensures that projects are executed effectively and that processes are constantly refined for better outcomes.</li>
                    <li><b>Order and Logic:</b> Logical thinking and a structured approach are key in problem-solving and project planning in the digital sector. These skills help in creating clear, effective strategies and solutions.</li>
                    <li><b>Patient and Committed:</b> Patience and commitment are essential for long-term success in digital careers, where complex tasks and ongoing learning are part of the job.</li>
                    <li><b>Problem Solving:</b> Problem-solving skills are critical in the digital field, as they enable professionals to navigate and overcome the various challenges that arise in their work.</li>
                    <li><b>Resilient and Positive:</b> Resilience and a positive attitude help digital professionals to persevere through setbacks and maintain momentum in their projects and career growth.</li>
                    <li><b>Team Player and Collaborative:</b> Collaboration is a cornerstone of digital work. Being a team player facilitates shared success and fosters a productive, supportive work environment.</li>
                </ul>
                <p>These attributes together form a comprehensive skill set that is highly valued in the digital space, enabling professionals to thrive in diverse roles and adapt to the dynamic nature of the industry.</p>

                <h3>Sources</h3>
                <p>As well as being developed through feedback from employers and learners in the field, there are a number of articles who reference these attributes;</p>

                <SourcesList>
                    <li>Northeastern University's discussion on digital communication, highlighting the importance of empathy in effective communication, from their article "What is Digital Communication? | In-Demand Skills and Careers".</li>
                    <li>London School of Economics (LSE) Careers blog discussing the importance of adaptability and creative thinking in careers, emphasizing the value of these skills in decision making and problem-solving, from their article "The importance of applying adaptability and creative thinking in your career".</li>
                    <li>Iconic Digital Agency's article on the importance of curiosity in business, discussing how a culture of curiosity drives business growth, fosters better solutions to company problems, and encourages flexible and innovative thinking, from their article "Why Curiosity is Good for Business (and how to foster it)".</li>
                    <li>SEEK's article on the importance of attention to detail: "Attention to detail: What it is and why it's important".</li>
                    <li>BetterUp's article on continuous improvement: "Continuous Improvement: 6 Stages to Follow and Why It's Important".</li>
                    <li>MIT Press's book on applying predicate logic in computer science: "Essential Logic for Computer Science".</li>
                    <li>YourCareerPathNow's guide on cultivating patience in a digital age: "Cultivating Patience in a Digital Age: A Guide to Professional Development".</li>
                </SourcesList>
 
        </ModalTemplate>
    )
}

export default AttributeInfo;

const SourcesList = styled.ul`
    font-size: 12px;
`;