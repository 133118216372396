import { useRef, useState } from "react";
import PageTransition from "../components/PageTransition.js";
import { Link, useNavigate } from "react-router-dom";
import { FormInput } from "../styles/Form.styles.js";
import { FormButton } from "../styles/Button.styles.js";
import styled from "styled-components";
import Loader from "../components/Loader.js";
import Cookies from "universal-cookie";
import { device } from "../styles/Responsive.style.js";

import { logIn } from "../utils/index.js";

const cookies = new Cookies();

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const emailRef = useRef();
    const passwordRef = useRef();

    const navigate = useNavigate();

    const handleLogIn = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        const data = await logIn(emailRef.current.value.toLowerCase(), passwordRef.current.value)
        setIsLoading(false);
        if(data.message) {
            setErrMsg(data.message);
            
            return;
        }
        if(data.token) {
            await cookies.set('token', data.token, { path: '/' });
            if(!data.registered) {
                navigate("/register")
                return;
            }
            navigate("/questionnaires")
        }
    }

    return (
        <>
            <SectionBlock>
                <LogInForm onSubmit={handleLogIn} autoComplete="on">
                    {isLoading && (
                        <LoaderWrapper>
                            <Loader themeType={"dark"} />
                        </LoaderWrapper>
                    )}
                    <h1>Log In</h1>
                    <FormInput>      
                        <input type="email" placeholder=" " ref={emailRef} required />
                        <label>Email</label>
                    </FormInput>
                    <FormInput>   
                        <input type="password" placeholder=" " ref={passwordRef} required />
                        <label>Password</label>
                    </FormInput>
                    <ErrorMessage>{errMsg}</ErrorMessage>
                    <ForgotPass to="/forgotPassword">Have you forgotten your password?</ForgotPass>
                    <FormButton type="submit">Log In</FormButton>
                </LogInForm>
            </SectionBlock>
            <PageTransition isLoading={false} />
        </>
    )
}

export default Login;

const SectionBlock = styled.section`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.small} {
        align-items: flex-start;
    }
`;

const LoaderWrapper = styled.div`
    position: absolute;
    height: calc(100% - 100px);
    width: calc(100% - 99px);
    background-color: white;
    z-index: 100;
    display: grid;
    place-items: center;
`;

const LogInForm = styled.form`
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    position: relative;
    z-index: 10;
    max-width: 1000px;
    margin: 20px;

    h1 {
        margin: 0;
        font-size: 48px;
    }

    button[type="submit"] {
        margin: 10px 0 0 0;
    }

    @media ${device.small} {
        width: 100%;
        padding: 20px;
        margin: 50px 5px 0;
    }
`;

const ErrorMessage = styled.p`
    color: red;
    margin: 0;
    height: 19px;
    margin-top: 10px;
`;

const ForgotPass = styled(Link)`
    display: block;
    margin: 10px 0;
    text-align: left;
    color: var(--blue);
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;