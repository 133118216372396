import styled from "styled-components";
import Kebab from "./Kebab";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { device } from "../../styles/Responsive.style";
import { useUser } from "../../context/UserContext";
import { useAdmin } from "../../context/AdminContext";
import AddUser from "../Modals/Groups/AddUser";
import AddUserSuccess from "../Modals/Groups/AddUserSuccess";

const GroupCard = ({ group_info }) => {
    const { blurryBg, setBlurryBg } = useUser();
    const [openGroupCard, setOpenGroupCard] = useState(false);
    const [moveLeft, setMoveLeft] = useState(0);
    const [openAddUserModal, setOpenAddUserModal] = useState(false);
    const [openAddUserSuccessModal, setOpenAddUserSuccessModal] = useState(false);
    const card = useRef();

    const { adminData, setNoInviteModal } = useAdmin();

    const toggleCardBtn = () => {

        let distanceFromSide = window.innerWidth - card.current.getBoundingClientRect().x
        if (window.innerWidth < 500) {
            if (distanceFromSide < window.innerWidth) {
                setMoveLeft(window.innerWidth - (distanceFromSide + 10))
            }
        } else {
            setMoveLeft(453 - distanceFromSide)
        }

        if (openGroupCard) {
            setOpenGroupCard(false)
            setMoveLeft(0)
            setBlurryBg(false)
        } else {
            setOpenGroupCard(true)
            setBlurryBg(true)
        }
    }

    useEffect(() => {
        if (!blurryBg && openGroupCard) {
            setOpenGroupCard(false);
        }
    }, [blurryBg])

    const closeCard = () => {
        setOpenGroupCard(false)
        setMoveLeft(0)
        setOpenAddUserModal(true)
    }

    return (
        <>
            <GroupCardWrapper>
                <ExpandingCard ref={card} className={openGroupCard ? "expanded" : ""} $moveLeft={moveLeft}>
                    <GroupInfoSection>
                        <section className={openGroupCard ? "full-text" : ""}>
                            <h3>{group_info.title}</h3>
                            <p>{group_info.description}</p>
                        </section>
                        <Kebab group_id={group_info._id} />
                    </GroupInfoSection>
                    <UserSection>
                        <div>
                            <p>Users</p>
                            <h4>{group_info.users.length}</h4>
                        </div>
                        <button className="addBtn" onClick={() => adminData.invites === 0 ? setNoInviteModal(true) : closeCard()}><AddIcon></AddIcon></button>
                    </UserSection>
                    <ExtraInfoSection className={openGroupCard ? "extra-open" : ""}>
                        <p>This is the data collected for each user added under this group:</p>
                        <ul>
                            <li>Email</li>
                            <li>Name</li>
                            {group_info.captured_data.map((data, i) => <li key={i}>{data.split(/(?=[A-Z])/).join(' ')}</li>)}
                        </ul>
                    </ExtraInfoSection>
                    <SeeMoreBtn onClick={toggleCardBtn}>{openGroupCard ? "See Less" : "See More"} <div className={openGroupCard ? "open" : ""}></div> </SeeMoreBtn>
                </ExpandingCard>
            </GroupCardWrapper>
            <AddUser showModal={openAddUserModal} setShowModal={setOpenAddUserModal} group_id={group_info._id} setOpenAddUserSuccessModal={setOpenAddUserSuccessModal} />
            <AddUserSuccess showModal={openAddUserSuccessModal} setShowModal={setOpenAddUserSuccessModal} />
        </>
    )
}

export default GroupCard;

const GroupCardWrapper = styled.div`
    position: relative;
    height: 209px;

    @media ${device.small} {
        height: 167px;
    }
`;

const ExpandingCard = styled.div`
    background-color: var(--bg-main);
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 var(--gray-light);
    position: absolute;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    left: -${(props) => props.$moveLeft}px;

    &.expanded {
        width: 443px;
        z-index: 999;

        .addBtn::after {
            content: "Add Users";
            margin-left: 10px;
        }
    }

    @media ${device.small} {
        padding: 12px;
        &.expanded {
            width: calc(100vw - 20px);
        }
    }
`;

const GroupInfoSection = styled.div`
    display: flex;

    & > section {
        flex-grow: 0;
        width: calc(100% - 23px);
    }

    h3 {
        font-size: var(--font-medium);
        margin: 0;
        text-overflow: ellipsis; 
        overflow: hidden; 
        white-space: nowrap;
    }
    .full-text h3 {
        text-overflow: clip;
        overflow: visible; 
        white-space: normal;
    }

    p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 19px;
        height: 38px;
        margin: 5px 0;
        font-size: var(--font-small);
    }
    .full-text p {
        display: block;
        height: auto;
        text-overflow: clip;
        overflow: visible; 
    }

    @media ${device.small} {
        h3 {
            font-size: var(--font-medium);
        }
    }

`;

const UserSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-weight: 900;
        margin: 10px 0 5px;
        font-size: var(--font-small);
    }

    h4 {
        font-size: var(--font-large);
        margin: 0;
    }

    button {
        border: none;
        font-weight: 900;
        font-size: var(--font-small);
        border-radius: 5px;
        padding: 10px 20px;
        color: var(--blue);
        background-color: var(--blue-light);
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: all 0.2s;

        &:hover {
            color: var(--bg-main);
            background-color: var(--blue);

            div {
                border: 2px solid var(--bg-main);
            }

            div::before, div::after {
                background-color: var(--bg-main);
            }
        }

        &::after {
            content: "Add Users";
            margin-left: 5px;
        }
    }

    @media ${device.small} {
        p {
            margin: 0;
        }

        h4 {
            font-size: var(--font-medium);
            margin: 0;
        }
        button {
            padding: 10px;
            &::after {
                content: "";
                margin-left: 0;
            }
        }
    }
`;

const AddIcon = styled.div`
    height: 14px;
    width: 14px;
    border-radius: 100%;
    border: 2px solid var(--blue);
    position: relative;
    transition: all 0.2s;

    &::before, &::after {
        position: absolute;
        content: "";
        width: 50%;
        height: 2px;
        background-color: var(--blue);
        border-radius: 10px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.2s;
    }

    &::before {
        transform: translate(-50%, -50%) rotate(90deg) ;
    }
`;

const ExtraInfoSection = styled.div`
    display: none;
    font-size: var(--font-small);

    ul {
        column-count: 2;
        column-gap:20px;
    }

    li {
        text-transform: capitalize;
    }

    &.extra-open {
        display: block;
    }
`;

const SeeMoreBtn = styled.div`
    text-align: center;
    font-size: var(--font-small);
    margin: 5px 0 0;
    color: var(--blue);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        height: 9px;
        width: 9px;
        position: relative;
        transform: rotate(135deg);
        margin-left: 10px;
        top: -1px;

        &::before, &::after {
            position: absolute;
            content: "";
            height: 100%;
            width: 2px;
            right: 0;
            background-color: var(--blue);
            border-radius: 5px;
        }
        &::before {
            transform-origin: top left;
            transform: rotate(90deg);
            right: -2px;
        }

        &.open {
            transform: rotate(-45deg);
            top: 3px;
        }
    }
`;