import { useEffect } from "react";
import { useAdmin } from "../../../context/AdminContext.js";
import { useUser } from "../../../context/UserContext.js";
import ModalTemplate from "../ModalTemplate.js";

import styled from "styled-components";

const DeleteGroupSuccess = ({ showModal, setShowModal, group_id }) => {

    const { setBlurryBg } = useUser();
    const { adminData, setAdminData } = useAdmin();

    const closeModal = () => {
        setShowModal(false);
        setBlurryBg(false);
    }

    useEffect(() => {
        return () => {
            let copy = {...adminData};
            copy.groups.splice(copy.groups.findIndex(obj => obj._id === group_id), 1);
            setAdminData(copy)
            window.location.reload();
        }
    }, [])

    return (
        <ModalTemplate showModal={showModal} setShowModal={setShowModal}>
            <ContentWrapper>
                <TickSymbol></TickSymbol>
                <h1>Success!</h1>
                <p>Group and all users under this group have successfully been deleted.</p>
                <button onClick={closeModal}>Ok</button>
            </ContentWrapper>
        </ModalTemplate>
    )
}

export default DeleteGroupSuccess;

const ContentWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h1, p, button {
        width: 280px;
    }
    h1 {
        font-size: 40px;
        margin: 10px;
        text-align: center;
    }
    p {
        margin: 0;
        text-align: center;
    }
    button {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        margin-top: 10px;
        cursor: pointer;
        background-color: var(--primary-gray);

        &:hover {
            background-color: var(--secondary-gray);
        }
    }
`;

const TickSymbol = styled.div`
    height: 100px;
    width: 100px;
    border: 3px solid var(--green);
    border-radius: 50%;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        height: 52px;
        width: 3px;
        top: calc(50% + 3px);
        left: calc(50% + 12px);
        transform: translate(-50%, -50%) rotate(45deg);
        border-radius: 5px;
        background-color: var(--green);
    }
    &::after {
        position: absolute;
        content: "";
        height: 28px;
        width: 3px;
        top: calc(50% + 12px);
        left: calc(50% - 15px);
        transform: translate(-50%, -50%) rotate(-45deg);
        border-radius: 5px;
        background-color: var(--green);
    }
`;