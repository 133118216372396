import longCNLogo from "../assets/images/longWhiteCNLogo.png";
import styled from "styled-components";
import digiDnaLogo from "../assets/images/digiDNALogo.png";
import { device } from "../styles/Responsive.style.js";
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <FooterWrapper>
            <LeftSection>
                <h2><Link to="/"><img src={digiDnaLogo} alt="Logo"/><b><span>digi</span>DNA</b></Link>powered by <a href="https://wearecodenation.com/" target="_blank" rel="noreferrer"><img className="cnLogo" src={longCNLogo} alt="Code Ntion Logo"/></a></h2>
                <a href="mailto:digidna@wearecodenation.com"><p>digidna@wearecodenation.com</p></a>
            </LeftSection>
            <RightSection>
                <p>digiDNA © 2024 | Privacy Policy | Terms of Service</p>
                <p>digiDNA is a registered trademark, all rights reserved.</p>
            </RightSection>
        </FooterWrapper>
    )
}

export default Footer;

const FooterWrapper = styled.footer`
    background-color: var(--dark-bg);
    display: flex;
    justify-content: space-between;
    padding: 50px;
    margin-top: 25px;
    
    & * {
        color: var(--text-light);
    }

    @media ${device.small} {
        flex-direction: column;
    }
`;

const LeftSection = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    a {
        text-decoration: none;
        display: block;
    }

    h2 {
        font-weight: 400;
        margin: 6px 0;
        color: var(--text-light);
        display: flex;
        align-items: center;
        font-size: 20px;
    }

    h2 a {
        display: flex;
        align-items: center;
    }

    b {
        text-decoration: none;
        font-size: 34px;
        color: var(--text-light);
        margin-right: 5px;
    }

    span {
        color: var(--text-light);
        font-weight: 400;
    }

    img {
        height: 30px;
        margin-right: 10px;
        margin-left: 5px;
    }

    p {
        flex-basis: 100%;
        margin: 6px 0;
    }
    
    @media ${device.medium} {
        flex-direction: column;
        align-items: flex-start;

        h2 {
            margin: 0;
        }

        p {
            flex-basis: auto;
            margin: 15px 0 0 0;
        }
    }

    @media ${device.small} {
        h2 {
            flex-direction: column;
            align-items: flex-start;
            font-size: 20px;
        }

        .cnLogo {
            height: auto;
            width: 100%;
            margin: 0px;
            max-width: 250px;
        }
    }
`;

const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    p {
        margin: 6px 0;
    }

    @media ${device.small} {
        margin-top: 25px;
    }
`;