import PageTransition from "../components/PageTransition.js";
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import QuestionCard from "../components/QuestionCard.js";
import { PrimaryButton } from "../styles/Button.styles.js";
import styled from "styled-components";
import { completeQuizAnswers, getSingleQuiz, saveQuizAnswers } from "../utils/index.js";
import IncompleteAnswers from "../components/Modals/IncompleteAnswers.js";
import { device } from "../styles/Responsive.style.js";

const Quiz = () => {
    const [quizData, setQuizData] = useState({});
    const [questionSections, setQuestionSections] = useState([]);
    const [activeSection, setActiveSection] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const questionsTop = useRef();

    const [incompleteModal, setIncompleteModal] = useState(false);

    let { id } = useParams();
    const navigate = useNavigate();

    const handleInitialFetch = async () => {
        let data = await getSingleQuiz(id);
        setQuizData(data.quiz)
       
        // create section list on the left and what question should be visible
        let sectionSize = 12;
        let questions = [];
        for (let i = 0; i < data.quiz.questions.length; i += sectionSize) {
            const section = data.quiz.questions.slice(i, i + sectionSize);
            questions.push(section)
        }
        setQuestionSections(questions)

        // sort answers already filled in
        let emptyAnswers = data.quiz.questions.map((question) => {
            return {
                question_id: question._id,
                answer_id: null,
                point: 0
            }
        });

        data.userAnswers.forEach((ans) => {
            let index = emptyAnswers.findIndex((question) => question.question_id === ans.question_id);
            if(index === -1) return;
            emptyAnswers[index] = ans;
        })

        setAnswers(emptyAnswers)
        setIsLoading(false);
    }

    useEffect(() => {
        handleInitialFetch()
    }, [])
    
    const completeQuiz = async (e) => {
        e.preventDefault();

        if(answers.filter((answer) => answer.answer_id === null).length > 0){
            setIncompleteModal(true);
            return;
        }

        await completeQuizAnswers(quizData._id, answers);
        navigate(`/results/${quizData._id}`);
    }

    const nextSection = (e) => {
        e.preventDefault();
        questionsTop.current?.scrollIntoView({behaviour: 'smooth'})
        setActiveSection(activeSection + 1);
    }

    const handleAnswerChange = async (questionId, answer, points) => {
        let answersCopy = [...answers];
        let index = answersCopy.findIndex(question => question.question_id === questionId)
        answersCopy[index].answer_id = answer;
        answersCopy[index].point = points;
        setAnswers(answersCopy);

        if(questionSections[activeSection].map(q => answers.find(a => a.question_id === q._id).answer_id === null).indexOf(true) === -1){
            await saveQuizAnswers(quizData._id, answers);
        }
        
    }

    return (
        <QuizWrapper>

            <TextHeader>
                <h1>{quizData.title}</h1>
                <p>{quizData.description}</p>
            </TextHeader>

            <Main>

                <Sidebar>
                    {questionSections.map((section, index) => {
                        return (
                            <button 
                                key={index} 
                                id={index === activeSection ? "active" : ""} 
                                className={answers.slice(index * questionSections[0].length, questionSections[0].length + (index * questionSections[0].length)).filter(ans => ans.answer_id !== null).length === questionSections[index].length ? "complete" : ""} 
                                onClick={() => {setActiveSection(index);questionsTop.current?.scrollIntoView({behaviour: 'smooth'})}}
                            >Section {index + 1}</button>
                        )
                    })}
                </Sidebar>

                <form ref={questionsTop}>
                    {questionSections[activeSection]?.map((question, index) => {
                        return(
                            <QuestionCard 
                                key={index} 
                                questionNum={index + 1} 
                                totalQuestions={questionSections[activeSection].length} 
                                questionInfo={question} 
                                savedAnswerId={answers[index + (questionSections[0].length * activeSection)].answer_id} 
                                changeAns={handleAnswerChange} 
                                isDisabled={false}
                            />
                        )
                    })}
                    {activeSection + 1 === questionSections.length ? (
                        <PrimaryButton onClick={completeQuiz}>Complete Quiz</PrimaryButton>
                    ) : (
                        <PrimaryButton onClick={nextSection}>Save Section</PrimaryButton>
                    )}
                </form>
            </Main>

            <IncompleteAnswers showModal={incompleteModal} setShowModal={setIncompleteModal} />

            <PageTransition isLoading={isLoading} />
        </QuizWrapper>
    )
}

export default Quiz;

const QuizWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 25px;
`;

const TextHeader = styled.header`
   width: 100%;
   max-width: 1500px;
`;

const Main = styled.main`
    display: flex;
    align-items: flex-start;
    max-width: 1500px;

    form {
        margin: 0;
        flex-grow: 1;
        
        & > button {
            margin: 25px 25px;
        }
    }

    @media ${device.small} {
        flex-direction: column;
    }
`;

const Sidebar = styled.aside`
    position: sticky;
    top: 25px;
    margin: 25px 0 0 0;
    width: 250px;
    min-width: 140px;
    height: auto;
    background-color: var(--primary-gray);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;

    button:not(:last-child) {
        border-bottom: 1px solid var(--secondary-gray);
    }

    button {
        width: 100%;
        text-align: left;
        padding: 10px;
        border-radius: 0px;
        border: none;
        background-color: var(--primary-gray);
        cursor: pointer;
        position: relative;
    }

    #active {
        background-color: var(--secondary-gray);
    }

    .complete::before {
        position: absolute;
        content: '';
        height: 25px;
        width: 25px;
        background-color: var(--accent);
        border-radius: 50%;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
      
    .complete::after {
        position: absolute;
        content: '';
        height: 10px;
        width: 4px;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        right: 18px;
        top: calc(50% - 1px);
        transform: translateY(-50%) rotate(45deg);
    }

    @media ${device.small} {
        position: static;
        width: 100%;
    }
`;