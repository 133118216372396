import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import styled from "styled-components";
import { getPDFCharts } from "../utils/downloads";
import { useAdmin } from "../context/AdminContext";
import { predictedJobs } from "../utils/predictedJobs";

// creates multiple spider charts to be used in admins PDF report
const SpiderChartReport = ({ users }) => {

    const { setGenerateSpiders, setGenerateSpidersForSelected, averageUri, spiderData } = useAdmin()

    const chartData = {
        fill: {
            opacity: 0.5,
            colors: ["#f5b32f"]
        },
        stroke: {
            width: 2,
            colors: ["#f5b32f"]
        },
        markers: {
            size: 4,
            colors: ['#ffa200'],
        },
        plotOptions: {
            radar: {
                polygons: {
                    fill: {
                        colors: ['#f0f0f0', '#f6f6f6']
                    }
                }
            }
        },
        xaxis: {
            categories: users.attributes.map((att,i) => {if(i === 0 || i ===5) return att;let a = att.split("& "); if(a.length > 1)a[0] += " &"; return a})
        },
        yaxis: {
            max: 4,
            min: 0,
            tickAmount: 4,
            labels: {
                offsetY: 5
            }
        },
        grid: {
            padding: {
              top: -70,
              bottom: -70
            }
        }
    };

    const getDataUri = async () => {
        const allImages = [];

        for(let userObj of [{ name: spiderData.title, scores: spiderData.avgScores , userId: "average-score" }, ...users.usersData]) {
            let uri = await ApexCharts.exec(userObj.userId, "dataURI");
            allImages.push({ name: userObj.name, image: uri.imgURI, jobs: predictedJobs(users.attributes, userObj.scores) })
        }
        getPDFCharts(allImages);
        setGenerateSpiders(false);
        setGenerateSpidersForSelected(false);
    }

    useEffect(() => {
        getDataUri();
    }, [])

    return (
        <>
            <ChartWrapper>
                {[{ scores: spiderData.avgScores , userId: "average-score" }, ...users.usersData].map((user, index) => {
                    return (
                        <div key={index}>
                            <ReactApexChart options={{
                                ...chartData, chart: {
                                    id: user.userId,
                                    fontFamily: 'Gilroy',
                                    animations: {
                                        enabled: false,
                                        speed: 0,
                                    }
                                }
                            }} series={[{ data: user.scores }]} type="radar" height="500px" width="500px" />
                        </div>
                    )
                })}

            </ChartWrapper>
        </>
    )
}

export default SpiderChartReport;

const ChartWrapper = styled.div`
  position: absolute;
  left: -200vw;
  top: 0;
  max-height: 100vh;
  width: 100vw;
`;