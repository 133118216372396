const jobs = [
    // {
    //   name: "UX/UI Designer",
    //   description: "Creates visually appealing and user-friendly interfaces for digital products, focusing on enhancing the overall user experience. Balances creativity with an understanding of user behavior to design interfaces that are both aesthetically pleasing and functional.",
    //   attributes: {
    //     "Communication & Empathy": 4,
    //     "Creative & Flexible": 4,
    //     "Curious & Inquisitive": 3,
    //     "Detailed & Technically Minded": 2,
    //     "Efficiency & Improvement": 3,
    //     "Order & Logic": 2,
    //     "Patient & Committed": 4,
    //     "Problem Solving": 3,
    //     "Resilient & Positive": 4,
    //     "Team Player & Collaborative": 4,
    //   },
    // },
    {
      name: "Software Developer",
      description: "Designs, develops, tests, and maintains software applications. Works on programming, coding, and debugging to create functional software solutions. Collaborates with cross-functional teams to ensure the delivery of high-quality software products.",
      attributes: {
        "Communication & Empathy": 3,
        "Creative & Flexible": 3,
        "Curious & Inquisitive": 4,
        "Detailed & Technically Minded": 4,
        "Efficiency & Improvement": 4,
        "Order & Logic": 3,
        "Patient & Committed": 3,
        "Problem Solving": 4,
        "Resilient & Positive": 3,
        "Team Player & Collaborative": 3,
      },
    },
    {
      name: "Data Analyst",
      description: "Analyzes and interprets complex data sets to provide insights and support decision-making. Utilizes statistical methods and data visualization tools to identify trends, patterns, and correlations within data. Communicates findings to stakeholders for informed business decisions.",
      attributes: {
        "Communication & Empathy": 3,
        "Creative & Flexible": 2,
        "Curious & Inquisitive": 4,
        "Detailed & Technically Minded": 4,
        "Efficiency & Improvement": 3,
        "Order & Logic": 3,
        "Patient & Committed": 3,
        "Problem Solving": 4,
        "Resilient & Positive": 2,
        "Team Player & Collaborative": 3,
      },
    },
    {
      name: "IT Support Specialist",
      description: "Provides technical support to end-users, addressing hardware and software issues. Installs and configures computer systems, troubleshoots network problems, and assists in maintaining IT infrastructure. Offers timely solutions to ensure seamless technology usage.",
      attributes: {
        "Communication & Empathy": 3,
        "Creative & Flexible": 2,
        "Curious & Inquisitive": 3,
        "Detailed & Technically Minded": 4,
        "Efficiency & Improvement": 3,
        "Order & Logic": 4,
        "Patient & Committed": 3,
        "Problem Solving": 3,
        "Resilient & Positive": 3,
        "Team Player & Collaborative": 3,
      },
    },
    // {
    //   name: "Digital Marketing Specialist",
    //   description: "Develops and executes digital marketing campaigns to promote products or services. Utilizes various online channels, including social media, email, and search engines, to drive brand awareness, engagement, and conversion. Analyzes campaign performance and adjusts strategies accordingly.",
    //   attributes: {
    //     "Communication & Empathy": 4,
    //     "Creative & Flexible": 4,
    //     "Curious & Inquisitive": 3,
    //     "Detailed & Technically Minded": 2,
    //     "Efficiency & Improvement": 3,
    //     "Order & Logic": 2,
    //     "Patient & Committed": 3,
    //     "Problem Solving": 3,
    //     "Resilient & Positive": 4,
    //     "Team Player & Collaborative": 3,
    //   },
    // },
    // {
    //   name: "Project Manager",
    //   description: "Leads and oversees project teams to ensure successful completion of projects within scope, budget, and timeline. Plans, executes, and monitors project activities, facilitating communication among team members and stakeholders. Manages risks and resolves issues to achieve project goals.",
    //   attributes: {
    //     "Communication & Empathy": 4,
    //     "Creative & Flexible": 3,
    //     "Curious & Inquisitive": 3,
    //     "Detailed & Technically Minded": 2,
    //     "Efficiency & Improvement": 4,
    //     "Order & Logic": 4,
    //     "Patient & Committed": 4,
    //     "Problem Solving": 3,
    //     "Resilient & Positive": 4,
    //     "Team Player & Collaborative": 4,
    //   },
    // },
    {
      name: "Cybersecurity Expert",
      description: "Implements and manages security measures to protect computer systems, networks, and sensitive data from cyber threats. Conducts risk assessments, implements security protocols, and monitors for potential security breaches. Responds to and mitigates security incidents.",
      attributes: {
        "Communication & Empathy": 3,
        "Creative & Flexible": 2,
        "Curious & Inquisitive": 4,
        "Detailed & Technically Minded": 4,
        "Efficiency & Improvement": 3,
        "Order & Logic": 4,
        "Patient & Committed": 3,
        "Problem Solving": 4,
        "Resilient & Positive": 3,
        "Team Player & Collaborative": 3,
      },
    },
    {
      name: "AI Researcher",
      description: "Engages in research and development activities focused on artificial intelligence (AI) and machine learning (ML). Explores innovative algorithms, models, and techniques to advance AI capabilities. Collaborates with interdisciplinary teams to apply AI solutions to real-world problems.",
      attributes: {
        "Communication & Empathy": 3,
        "Creative & Flexible": 4,
        "Curious & Inquisitive": 4,
        "Detailed & Technically Minded": 4,
        "Efficiency & Improvement": 3,
        "Order & Logic": 3,
        "Patient & Committed": 3,
        "Problem Solving": 4,
        "Resilient & Positive": 3,
        "Team Player & Collaborative": 3,
      },
    },
    // {
    //   name: "Cloud Engineer",
    //   description: "Designs, implements, and manages cloud-based infrastructure and services. Works with cloud platforms such as AWS, Azure, or Google Cloud to optimize performance, scalability, and reliability. Collaborates with development teams to deploy and maintain applications in the cloud.",
    //   attributes: {
    //     "Communication & Empathy": 3,
    //     "Creative & Flexible": 3,
    //     "Curious & Inquisitive": 3,
    //     "Detailed & Technically Minded": 4,
    //     "Efficiency & Improvement": 4,
    //     "Order & Logic": 4,
    //     "Patient & Committed": 3,
    //     "Problem Solving": 3,
    //     "Resilient & Positive": 3,
    //     "Team Player & Collaborative": 3,
    //   },
    // },
    // {
    //   name: "DevOps Engineer",
    //   description: "Integrates development and operations processes to enhance collaboration and efficiency. Automates and streamlines workflows for software delivery and infrastructure management. Focuses on continuous integration, continuous deployment, and monitoring to improve overall system reliability.",
    //   attributes: {
    //     "Communication & Empathy": 3,
    //     "Creative & Flexible": 3,
    //     "Curious & Inquisitive": 3,
    //     "Detailed & Technically Minded": 4,
    //     "Efficiency & Improvement": 4,
    //     "Order & Logic": 4,
    //     "Patient & Committed": 3,
    //     "Problem Solving": 3,
    //     "Resilient & Positive": 3,
    //     "Team Player & Collaborative": 4,
    //   },
    // },
  ];
  

export const predictedJobs = (finalAttributes, avgScores) => {
    const jobScores = [];

    for (const job of jobs) {
        let totalScore = 0;
        
        for (let i = 0; i < finalAttributes.length; i++) {
            const responseScore = avgScores[i] || 0;
            const attributeWeight = job.attributes[finalAttributes[i]];
            totalScore += responseScore * attributeWeight;
        }
    
        jobScores.push({ job: job.name, score: totalScore, desc: job.description });
    }
    
    jobScores.sort((a, b) => b.score - a.score);
  
    return jobScores.slice(0,3);
}