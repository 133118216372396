import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import GilroyBold from "../assets/fonts/Gilroy-ExtraBold.woff";
import GilroyLight from "../assets/fonts/Gilroy-Light.woff";
import topLogo from "../assets/images/pdfTopLogo.png";

Font.register({ family: 'Gilroy', fonts: [
    {src: GilroyBold, fontWeight: "bold"},
    {src: GilroyLight, fontWeight: "light"}
] });

// Create styles
const styles = StyleSheet.create({
    header: {
        fontSize: 12,
        marginBottom: 10,
        justifyContent: "center",
        color: 'white',
        backgroundColor: "#222222",
        padding: "23px 20px 20px 20px",
    },
    topLogo: {
        width: "300px",
    },
    contentWrapper: {
        paddingHorizontal: "30px",
        fontFamily: "Gilroy",
    },
    titleName: {
        fontSize: "25px"
    },
    titleQuiz: {
        fontSize: "16px"
    },
    titleDate: {
        fontSize: "13px",
        color: "#ffce6b",
        fontWeight: "light"
    },
    resultWrapper: {
        flexDirection: "row",
        alignItems: "center",
        marginVertical: "10px",
    },
    key: {
        fontSize: "10px",
        paddingLeft: "20px",
        fontWeight: "light",
    },
    scoreWrapper: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: "10px",
    },
    progWrapper: {
        flexDirection: "row",
        width: "50%",
        padding: "2px 5px",
        alignItems: "center"
    },
    attValue: {
        width: "40px",
        fontSize: "10px",
        fontWeight: "light",
    },
    progBar: {
        marginLeft: "10px",
        width: "100%",
        height: "10px",
        flex: 1,
        backgroundColor: "#f6f6f6",
        borderRadius: "5px",
        overflow: "hidden"
    },
    actualProg: {
        width: "100%",
        height: "100%",
        backgroundColor: "#ffce6b"
    },
    jobCard: {
        backgroundColor: "#f6f6f6",
        borderRadius: "10px",
        padding: "10px",
        marginVertical: "5px"
    },
    jobSub: {
        color: "#f5b32f",
        fontSize: "11px",
        fontWeight: "light",
        marginTop: "10px"
    },
    jobFirstSub: {
        color: "#f5b32f",
        fontSize: "11px",
        fontWeight: "light",
    },
    jobFirstTitle: {
        fontSize: "20px",
        marginBottom: "5px",
    },
    jobTitle: {
        fontSize: "15px",
        marginBottom: "5px",
    },
    jobDesc: {
        fontSize: "10px",
        fontWeight: "light",
    },
    spiderImg: {
        width: "60%",
    }
});

// Create Document Component
const SummaryReport = (summaryData) => {
    return (
        <Document>
            <Page size="A4">
                <View style={styles.header} fixed>
                    <Image style={styles.topLogo} src={topLogo} />
                </View>    
                <View style={styles.contentWrapper}>
                    <Text style={styles.titleName}>{summaryData.name}'s Summary</Text>
                    <Text style={styles.titleQuiz}>{summaryData.title}</Text>
                    <Text style={styles.titleDate}>Completed on {summaryData.dateCompleted}</Text>
                    <View style={styles.resultWrapper}>
                        <Image style={styles.spiderImg} src={summaryData.image} />
                        <View style={styles.key}>
                            {summaryData.attributes.map((att, i) => {
                                return (
                                    <Text key={i}>{att.split("& ").map(s => s[0]).join('')} - {att}</Text>
                                )
                            })}
                        </View>
                    </View>
        
                    <View style={styles.scoreWrapper}>
                        {summaryData.scores.map((sc, i) => {
                            return (
                                <View key={i} style={styles.progWrapper}>
                                    <Text style={styles.attValue}>{summaryData.attributes[i].split("& ").map(s => s[0]).join('')} - {sc}</Text>
                                    <View style={styles.progBar}>
                                        <View style={{width: `${(parseInt(sc*10)/40) * 100}%`}}>
                                            <View style={styles.actualProg}></View>
                                        </View>
                                    </View>
                                </View>
                            )
                        })}
                    </View>
        
                    <View style={styles.jobCard}>
                        <Text style={styles.jobFirstSub}>Matched Jobs</Text>
                        <Text style={styles.jobFirstTitle}>{summaryData.jobs[0].job}</Text>
                        <Text style={styles.jobDesc}>{summaryData.jobs[0].desc}</Text>
                        <Text style={styles.jobTitle}>{summaryData.jobs[1].job}</Text>
                        <Text style={styles.jobDesc}>{summaryData.jobs[1].desc}</Text>
                        <Text style={styles.jobTitle}>{summaryData.jobs[2].job}</Text>
                        <Text style={styles.jobDesc}>{summaryData.jobs[2].desc}</Text>
                    </View>
                </View>  
            </Page>
        </Document>
    )
};

export default SummaryReport;