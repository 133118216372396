import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PageTransition from "../components/PageTransition.js";
import { getYourResults } from "../utils/index.js";
import styled from "styled-components";
import QuestionCard from "../components/QuestionCard.js";
import SpiderChart from "../components/SpiderChart.js";
import { device } from "../styles/Responsive.style.js";
import AttributeInfo from "../components/Modals/AttributeInfo.js";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { predictedJobs } from "../utils/predictedJobs.js";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SummaryReport from "../components/PDFResultSummary.js";

const cookies = new Cookies();

const Results = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [userResults, setUserResults] = useState({});
    const [questionSections, setQuestionSections] = useState([]);
    const [activeSection, setActiveSection] = useState(0);
    const [quizData, setQuizData] = useState({});
    const [spiderData, setSpiderData] = useState({});
    const [userName, setUserName] = useState("");
    const [bestJobs, setBestJobs] = useState([]);
    const [summaryData, setSummaryData] = useState(null);

    const [moreInfo, setMoreInfo] = useState(false);
    const questionsTop = useRef();

    let { quizId } = useParams();

    const handleInitialFetch = async () => {
        let data = await getYourResults(quizId);
        setUserResults(data.userAnswers)
        setQuizData(data.quizInfo)

        const token = await cookies.get("token");
        if(token !== undefined){
            const decoded = jwtDecode(token);
            setUserName(decoded.name)
        }

        let sectionSize = 12;
        let questions = [];
        for (let i = 0; i < data.quizInfo.questions.length; i += sectionSize) {
            const section = data.quizInfo.questions.slice(i, i + sectionSize);
            questions.push(section)
        }
        setQuestionSections(questions)

        // get average of scores for each attribute
        let attributes = [...new Set(data.quizInfo.questions.map(q => q.attribute))]
        let scores = attributes.map((el) => [])
        data.userAnswers.answers.forEach((answer) => {
            let questionObj = data.quizInfo.questions.find(q => q._id === answer.question_id);
            let chosenAnsObj = questionObj.answers.find(ans => ans._id === answer.answer_id);
            scores[attributes.indexOf(questionObj.attribute)].push(chosenAnsObj.points);
        })

        let avgScores = scores.map((att) => (att.reduce(( p, c ) => p + c, 0 ) / att.length).toFixed(1))
        setSpiderData({ attribute: attributes.map((att,i) => {if(i === 0 || i ===5) return att;let a = att.split("&"); if(a.length > 1)a[0] += " &"; return a}), avgScores: avgScores})

        setSummaryData({ name: jwtDecode(token).name, dateCompleted: new Date(data.userAnswers.date_completed).toLocaleDateString("en-GB", {year: "numeric", month: "short", day:"numeric"}),title: data.quizInfo.title ,attributes: attributes, scores: avgScores,jobs: predictedJobs(attributes, avgScores)})

        setBestJobs(predictedJobs(attributes, avgScores))

        setIsLoading(false);
    }

    useEffect(() => {
        // show off the loading screen
        setTimeout(() => {
            handleInitialFetch();
        }, 750);
    }, [])

    return (
        <ResultsWrapper>
            <TextHeader>
                <h1>{quizData.title}</h1>
                <h3>Completed on {new Date(userResults.date_completed).toLocaleDateString("en-GB", {year: "numeric", month: "short", day:"numeric"})}</h3>
                <p>{quizData.description}</p>

                {summaryData?.image !== undefined && (
                    <>
                        <PDFDownloadLink className="downloadPdf" document={SummaryReport(summaryData)} fileName="digiDNA-Summary.pdf">
                            {({ loading }) => {
                                return loading ? "Loading document..." : "Download Summary"
                            }}
                        </PDFDownloadLink>
                    </>

                )}
                

                <h2>Results</h2>
                <p>Your scores have been collected and plotted against the 10 attributes of a developer, with 4 being the perfect score. It's important to note, this view serves as a guide to show where you are against those attributes, recognised as being important in a digital role.</p>

                <ResultsSection>
                    {!isLoading && (
                        <div className="radarWrapper">
                            <SpiderChart categories={spiderData.attribute} series={[{name: quizData.title, data: spiderData.avgScores}]} title={`${userName}'s digiDNA Attributes`} setSummaryData={setSummaryData} forDoc={true}/>
                            <SpiderChart categories={spiderData.attribute} series={[{name: quizData.title, data: spiderData.avgScores}]} title={`${userName}'s digiDNA Attributes`} setSummaryData={setSummaryData} forDoc={false}/>
                        </div>
                    )}
                    <ResultRightSection>
                        <GridWrapper>
                            <div>
                                <p>WAY</p>
                                <ul>
                                    <li>Patient & Committed</li>
                                    <li>Efficiency & Improvement</li>
                                    <li>Resilient & Positive</li>
                                </ul>
                            </div>
                            <div>
                                <p>KNOW</p>
                                <ul>
                                    <li>Curious & Inquisitive</li>
                                    <li>Detailed & Technically Minded</li>
                                </ul>
                            </div>
                            <div>
                                <p>HUMAN</p>
                                <ul>
                                    <li>Communication & Empathy</li>
                                    <li>Team Player & Collaborative</li>
                                </ul>
                            </div>
                            <div>
                                <p>THINK</p>
                                <ul>
                                    <li>Order & Logic</li>
                                    <li>Creative & Flexible</li>
                                    <li>Problem Solving</li>
                                </ul>
                            </div>
                        </GridWrapper>
                        <p onClick={() => setMoreInfo(true)}>More Information</p>
                    </ResultRightSection>
                </ResultsSection>
                

                <PredictedJobsSection>
                    <section>
                        <h2 id="firstTitle">{bestJobs[0]?.job}</h2>
                        <p>{bestJobs[0]?.desc}</p>
                    </section>
                    <aside>
                        <div>
                            <h2>{bestJobs[1]?.job}</h2>
                            <p>{bestJobs[1]?.desc}</p>
                        </div>
                        <div>
                            <h2>{bestJobs[2]?.job}</h2>
                            <p>{bestJobs[2]?.desc}</p>
                        </div>
                    </aside>
                </PredictedJobsSection>
                <h2>Your answers</h2>
            </TextHeader>

            <AnswerSection>

                <Sidebar>
                    {questionSections.map((section, index) => {
                        return (
                            <button 
                                key={index} 
                                id={index === activeSection ? "active" : ""} 
                                onClick={() => {setActiveSection(index);questionsTop.current?.scrollIntoView({behaviour: 'smooth'})}}
                            >Section {index + 1}</button>
                        )
                    })}
                </Sidebar>

                <form ref={questionsTop}>
                    {questionSections[activeSection]?.map((question, index) => {
                        return(
                            <QuestionCard 
                                key={index} 
                                questionNum={index + 1} 
                                totalQuestions={questionSections[activeSection].length} 
                                questionInfo={question} 
                                savedAnswerId={userResults.answers[index + (questionSections[0].length * activeSection)].answer_id} 
                                changeAns={null} 
                                isDisabled={true}
                            />
                        )
                    })}
                </form>
            </AnswerSection>

            <AttributeInfo showModal={moreInfo} setShowModal={setMoreInfo}/>
            <PageTransition isLoading={isLoading} />
        </ResultsWrapper>
    )
}

export default Results;

const ResultsWrapper = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 25px;
`;

const TextHeader = styled.header`
   width: 100%;
   max-width: 1500px;

   h1 {
        margin: 25px 0 0 0;
   }
   h3 {
        margin: 0;
        font-weight: 400;
        color: var(--accent);
   }

   .downloadPdf {
        border: 3px solid var(--accent);
        border-radius: 5px;
        background-color: var(--accent);
        font-size: 13px;
        font-weight: 900;
        color: white;
        cursor: pointer;
        letter-spacing: 1px;
        box-shadow: 0 0 0 0px white inset;
        transition: all 0.2s;
        text-decoration: none;
        padding: 5px 15px;
        margin: 0 10px 0 0;

        &:hover {
            box-shadow: 0 0 0 2px white inset;
            background-color: var(--accent-hover);
        }
   }
`;

const ResultsSection = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    .radarWrapper {
        width: 50%;
    }

    @media ${device.small} {
        flex-direction: column;
        align-items: center;

        .radarWrapper {
            width: 100%;
        }
    }
`;

const ResultRightSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > p {
        color: var(--blue);
        cursor: pointer;
        margin: 10px 0 0;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const GridWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 650px;

    div {
        width: 50%;
        background-color: rgba(245, 179, 47, 0.5);
        padding: 20px 10px;
        box-sizing: border-box;
    }

    div:nth-child(2), div:nth-child(3){
        background-color: rgba(245, 179, 47, 0.25);
    }

    p {
        margin: 0;
    }
    ul {
        margin: 0;
        padding: 0 0 0 27px;
    }

    @media ${device.small} {
        margin: 20px;
        width: 100%;
        font-size: 12px;
    }
`;

const PredictedJobsSection = styled.main`
    display: flex;
    gap: 5px;

    section {
        background-color: var(--primary-gray);
        border-radius: 10px;
        padding: 10px;
        width: 40%;
    }

    aside {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 60%;
    }
    aside > div {
        background-color: var(--primary-gray);
        border-radius: 10px;
        padding: 10px;
    }

    h3 {
        margin: 0;
        font-size: 15px;
        color: var(--accent);
    }

    h2 {
        margin: 0 0 10px 0;
    }

    p {
        margin: 0;
    }

    #firstSub {
        font-size: 20px;
    }

    #firstTitle {
        font-size: 35px;
    }

    @media ${device.small} {
        margin-top: 25px;
        flex-direction: column;
        section, aside {
            width: auto;
        }
    }
`;

const AnswerSection = styled.section`
    display: flex;
    align-items: flex-start;
    max-width: 1500px;

    form {
        margin: 0;
        flex-grow: 1;
    }

    @media ${device.small} {
        flex-direction: column;
    }
`;

const Sidebar = styled.aside`
    position: sticky;
    top: 25px;
    margin: 25px 0 0 0;
    width: 250px;
    height: auto;
    background-color: var(--primary-gray);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;

    button:not(:last-child) {
        border-bottom: 1px solid var(--secondary-gray);
    }

    button {
        width: 100%;
        text-align: left;
        padding: 10px;
        border-radius: 0px;
        border: none;
        background-color: var(--primary-gray);
        cursor: pointer;
        position: relative;
    }

    #active {
        background-color: var(--secondary-gray);
    }

    .complete::before {
        position: absolute;
        content: '';
        height: 25px;
        width: 25px;
        background-color: var(--accent);
        border-radius: 50%;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
      
    .complete::after {
        position: absolute;
        content: '';
        height: 10px;
        width: 4px;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        right: 18px;
        top: calc(50% - 1px);
        transform: translateY(-50%) rotate(45deg);
    }

    @media ${device.small} {
        position: static;
        width: 100%;
    }
`;