import { predictedJobs } from "./predictedJobs";
import * as XLSX from "xlsx";
import ResultReportTable from "../components/PDFTableReport";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import ResultReport from "../components/PDFReport";

export const calculateResults = (adminData, quiz, users) => {
    let att = [...new Set(quiz.questions.map(q => q.attribute))]

    let userReportData = [];
    let includedUsers = users.map(user => {if(user.results.find(q => q.quizId === quiz._id && q.date_completed !== null) !== undefined){return user} }).filter(q => q !== undefined);
    includedUsers.forEach((user) => {
        let scores = att.map((el) => [])
        user.results.find(r => r.quizId === quiz._id).answers.forEach((answer) => {
            let questionObj = quiz.questions.find(q => q._id === answer.question_id);
            let chosenAnsObj = questionObj.answers.find(ans => ans._id === answer.answer_id);
            scores[att.indexOf(questionObj.attribute)].push(chosenAnsObj.points);
        })
        userReportData.push({email: user.email, userId: user._id, name: user.name, scores: scores.map((att) => (att.reduce(( p, c ) => p + c, 0 ) / att.length).toFixed(1))})
    })

    return {compName: adminData.company, quizName: quiz.title, usersData: userReportData, attributes: att }
}

export const getPDFTable = async (adminData, quiz, users) => {
    const props = await calculateResults(adminData, quiz, users);
    const doc = <ResultReportTable {...props} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, 'digiDNA-table-report.pdf');
}

export const getPDFCharts = async (images) => {
    const doc = <ResultReport images={images} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, 'digiDNA-report.pdf');
}

export const getCSVData = (adminData, quiz, users) => {

    let data = calculateResults(adminData, quiz, users)

    let csvData = []

    csvData.unshift([`digiDNA ${data.quizName} Results for ${adminData.company}`])

    csvData.push(["Name", "Email", ...data.attributes, "Total", "Matched Jobs", "", ""])

    
    data.usersData.forEach(user => {
        let jobs = predictedJobs(data.attributes, user.scores.map(s => parseFloat(s)))
        csvData.push([user.name, user.email, ...user.scores, user.scores.reduce((a, b) => parseInt(a) + parseInt(b)), ...jobs.map(j => j.job)])
    })

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(csvData);

    XLSX.utils.book_append_sheet(wb, ws, data.quizName);

    XLSX.writeFile(wb, "digiDNA-results.xlsx");
}