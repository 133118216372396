import { useUser } from "../../context/UserContext.js";
import ModalTemplate from "./ModalTemplate.js";

import styled from "styled-components";

const NoInvites = ({ showModal, setShowModal }) => {

    const { setBlurryBg } = useUser()
    const closeModal = () => {
        setShowModal(false);
        setBlurryBg(false);
    }

    return (
        <ModalTemplate showModal={showModal} setShowModal={setShowModal}>
            <ContentWrapper>
                <CrossSymbol></CrossSymbol>
                <h1>Oh No...</h1>
                <p>You have run out of invites.<br/>To request more invites you can get in touch with <a href="mailto:digidna@wearecodenation.com?subject=Request More Invites"><b>digidna@wearecodenation.com</b></a></p>
                <button onClick={closeModal}>Ok</button>
            </ContentWrapper>
        </ModalTemplate>
    )
}

export default NoInvites;

const ContentWrapper = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1, p, button {
        width: 280px;
    }

    h1 {
        font-size: 40px;
        margin: 10px;
        text-align: center;
    }
    p {
        margin: 0;
        text-align: center;
    }
    button {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        margin-top: 10px;
        cursor: pointer;
        background-color: var(--primary-gray);

        &:hover {
            background-color: var(--secondary-gray);
        }
    }
`;

const CrossSymbol = styled.div`
    height: 100px;
    width: 100px;
    border: 3px solid var(--red);
    border-radius: 50%;
    position: relative;

    &::before {
        position: absolute;
        content: '';
        height: 61px;
        width: 3px;
        border-radius: 100px;
        background-color: var(--red);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
        position: absolute;
        content: '';
        height: 61px;
        width: 3px;
        border-radius: 100px;
        background-color: var(--red);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
    }
`;