import Cookies from "universal-cookie";
const cookies = new Cookies();

// ------------------------------------
// These are Requests for the Quiz data
// ------------------------------------

export const getAllQuizzes = async () => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/quizzes/getAllQuizzes`, {
            method: "GET",
            headers: {Authorization: `Bearer ${cookies.get("token")}`}
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}

export const getSingleQuiz = async (id) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/quizzes/getQuiz/${id}`, {
            method: "GET",
            headers: {Authorization: `Bearer ${cookies.get("token")}`}
        });
        let data = await response.json();
        return data; 
    } catch (error) {
        console.log(error)
    }
}

export const saveQuizAnswers = async (quizId, answersArray) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/saveQuizAnswers`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${cookies.get("token")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                quizId: quizId,
                results: answersArray
            })
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}

export const completeQuizAnswers = async (quizId, answersArray) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/completeQuiz`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${cookies.get("token")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                quizId: quizId,
                results: answersArray
            })
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}

export const getYourResults = async (id) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/getYourResults/${id}`, {
            method: "GET",
            headers: {Authorization: `Bearer ${cookies.get("token")}`}
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}

export const getQuizName = async () => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/quizzes/getQuizName`, {
            method: "GET",
            headers: {Authorization: `Bearer ${cookies.get("token")}`}
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}

export const getUserResultsAdmin = async (qId, uId) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/getUserResultsAdmin/${qId}/${uId}`, {
            method: "GET",
            headers: {Authorization: `Bearer ${cookies.get("token")}`}
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}

// -------------------------------------------
// These are Requests for the Mainly User data
// -------------------------------------------

export const signUpUser = async (companyId, groupId, userInfo) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/signup`,{
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                companyId: companyId, 
                groupId: groupId,
                userInfo: userInfo
            })
        })
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}

export const logIn = async (email, pass) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/authenticate`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: email,
                password: pass
            })
        })
        const data = await response.json();

        return data;
    } catch (err) {
        console.log(err)
    }
}

export const registerUser = async (name, pass, extra_data, tandc) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/updateUserInfo`, {
            method: "PUT",
            headers: { 
                Authorization: `Bearer ${cookies.get("token")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                password: pass,
                extra_data: extra_data,
                tandc: tandc
            })
        })
        const data = await response.json();
        return data; 
    } catch (err) {
        console.log(err)
    }
}

export const registerAdmin = async (name, pass, comp, tandc) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/updateAdminInfo`, {
            method: "PUT",
            headers: { 
                Authorization: `Bearer ${cookies.get("token")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                password: pass,
                company: comp,
                tandc: tandc
            })
        })
        const data = await response.json();
        return data; 
    } catch (err) {
        console.log(err)
    }
}

export const getUsers = async () => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/getUsers`, {
            method: "GET",
            headers: {Authorization: `Bearer ${cookies.get("token")}`}
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}


export const addNewUsers = async (emails, group_id) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/addNewUsers`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${cookies.get("token")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                emails: emails,
                group_id: group_id
            })
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}

export const addNewAdmin = async (email, invites) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/addNewAdmin`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${cookies.get("token")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                invites: invites
            })
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}

export const requestResetPassword = async (email) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/auth/requestResetPassword`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email
            })
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}

export const resetPassword = async (uId, token, pass) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/auth/resetPassword`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: uId,
                token: token,
                password: pass
            })
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}

export const getAllAdmins = async () => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/getAdmins`, {
            method: "GET",
            headers: {Authorization: `Bearer ${cookies.get("token")}`}
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}

export const addInvites = async (adminId, invites) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/addInvites`, {
            method: "PUT",
            headers: { 
                Authorization: `Bearer ${cookies.get("token")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                adminId: adminId,
                invites: invites
            })
        })
        const data = await response.json();
        return data; 
    } catch (err) {
        console.log(err)
    }
}

export const deleteUsers = async (usersToDelete) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deleteUsers`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${cookies.get("token")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                users: usersToDelete
            })
        })
        const data = await response.json()
        return data;
    } catch (err) {
        console.log(err)
    }
}

export const deleteAdmin = async (adminID) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deleteAdmin`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${cookies.get("token")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                adminID: adminID
            })
        })
        const data = await response.json()
        console.log(data)
        return data;
    } catch (err) {
        console.log(err)
    }
}

// -------------------------------------------
// These are Requests for the Groups
// -------------------------------------------

export const createGroup = async (title, description, captured_data, skills) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/addGroup`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${cookies.get("token")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                title: title,
                description: description,
                captured_data: captured_data,
                skills: skills
            })
        })
        const data = await response.json()
        return data;
    } catch (err) {
        console.log(err)
    }
}

export const editGroup = async (title, description, group_id) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/editGroup`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${cookies.get("token")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                title: title,
                description: description,
                group_id: group_id
            })
        })
        const data = await response.json()
        return data;
    } catch (err) {
        console.log(err)
    }
}

export const deleteGroup = async (group_id) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deleteGroup`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${cookies.get("token")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                group_id: group_id
            })
        })
        const data = await response.json()
        return data;
    } catch (err) {
        console.log(err)
    }
}

// ------------------------------------------------------------------
// Invite Page - Check if there are enough invites and get group info
// ------------------------------------------------------------------

export const getGroup = async (companyId, groupId) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/getGroup/${companyId}/${groupId}`, {
            method: "GET",
            headers: {Authorization: `Bearer ${cookies.get("token")}`}
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}

export const getGroupRegister = async () => {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/getGroupRegister`, {
            method: "GET",
            headers: {Authorization: `Bearer ${cookies.get("token")}`}
        });
        let data = await response.json();

        return data; 
    } catch (error) {
        console.log(error)
    }
}