import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import styled from "styled-components";
import { device } from "../styles/Responsive.style.js";
import { useEffect } from "react";

const SpiderChart = ({categories, series, title, setSummaryData, forDoc}) => {

    const chartData = {
        title: {
          text: title
        },
        fill: {
          opacity: series.length === 1 ? 0.5 : 1/series.length,
          colors: series.length === 1 ? ["#f5b32f"] : undefined
        },
        chart: {
          fontFamily: 'Gilroy'
        },
        stroke: {
          width: 2,
          colors: series.length === 1 ? ["#f5b32f"] : undefined
        },
        markers: {
          size: series.length === 1 ? 4 : 0,
          colors: series.length === 1 ? ['#ffa200'] : undefined,
        },
        plotOptions: {
          radar: {
            polygons: {
              fill: {
                colors: ['#f0f0f0', '#f6f6f6']
              }
            }
          }
        },
        xaxis: {
          categories: categories
        },
        yaxis: {
          max: 4,
          min: 0,
          tickAmount: 4,
          labels: {
            offsetY: 5
          }
        },
        series: series,
        grid: {
          padding: {
            top: -60,
            bottom: -70
          }
        }
    };

    const getDataUri = async () => {      
      let res = await ApexCharts.exec("radarChartId", "dataURI")
      setSummaryData(prev => {return {...prev, image: res.imgURI} })  
    }

    useEffect(() => {
      if(setSummaryData !== false){
        getDataUri()
      }

    }, [])

    return (
        <>
          {forDoc ? (
            <ChartWrapperForDoc>
              <ReactApexChart options={{...chartData, chart: {
                id: "radarChartId",
                fontFamily: 'Gilroy',
                animations: {
                  enabled: false,
                  speed: 0,
              }}}} series={chartData.series} type="radar" height="500px" width="650px"/>
              </ChartWrapperForDoc>
          ): (
            <ChartWrapper>
              <ReactApexChart options={chartData} series={chartData.series} type="radar" height="100%" width="100%"/>
            </ChartWrapper>
          )}
        </>
    )
}

export default SpiderChart;

const ChartWrapper = styled.div`
  height: 450px;
  width: 100%;

  .apexcharts-toolbar {
    z-index: 0 !important;
  }

  @media ${device.medium} {
    height: 350px;
  }
`;

const ChartWrapperForDoc = styled.div`
  position: absolute;
  left: -200vw;
  top: 0;
  max-height: 100vh;
  width: 100vw;
`;
